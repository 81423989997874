export const timeframeToInterval = {
	'1h': 300,
	'24h': 300,
	'1d': 300,
	'7d': 1200,
	'1w': 1200,
	'1M': 4800,
	'30d': 4800,
	'1y': 76800,
};
