export const klayrTokenCompact = {
	decimal: 8,
	logo: 'https://raw.githubusercontent.com/KlayrHQ/app-registry/main/mainnet/Klayr/images/tokens/klayr.png',
	symbol: 'KLY',
	tokenId: '00000000000000',
	tokenName: 'Klayr',
};

export const swaptoshiTokenCompact = {
	decimal: 8,
	logo: 'https://raw.githubusercontent.com/Swaptoshi/app-registry/main/devnet/Swaptoshi/images/tokens/swx.png',
	symbol: 'SWX',
	tokenId: '55555500000000',
	tokenName: 'Swaptoshi',
};
