export const WC_PROJECT_ID = process.env.REACT_APP_WC_PROJECT_ID
	? process.env.REACT_APP_WC_PROJECT_ID
	: '43f4eccd4d8f7ea3cb05599d397103cd';

export const WC_PROJECT_NAME = process.env.REACT_APP_WC_PROJECT_NAME
	? process.env.REACT_APP_WC_PROJECT_NAME
	: 'Swaptoshi';

export const WC_TOKEN_SYMBOL = process.env.REACT_APP_WC_TOKEN_SYMBOL
	? process.env.REACT_APP_WC_TOKEN_SYMBOL
	: 'SWX';

export const WC_TOKEN_DECIMAL = process.env.REACT_APP_WC_TOKEN_DECIMAL
	? process.env.REACT_APP_WC_TOKEN_DECIMAL
	: '8';

export const WC_PROJECT_URL = process.env.REACT_APP_WC_PROJECT_URL
	? process.env.REACT_APP_WC_PROJECT_URL
	: 'https://app.swaptoshi.com';

export const WC_PROJECT_ICON = process.env.REACT_APP_WC_PROJECT_ICON
	? process.env.REACT_APP_WC_PROJECT_ICON
	: 'https://raw.githubusercontent.com/Swaptoshi/app-registry/main/devnet/Swaptoshi/images/application/swaptoshi.svg';

export const CHAIN_SUFFIX = process.env.REACT_APP_CHAIN_SUFFIX
	? process.env.REACT_APP_CHAIN_SUFFIX
	: '555555';

export const KLAYR_SERVICE_URL = process.env.REACT_APP_KLAYR_SERVICE_URL
	? process.env.REACT_APP_KLAYR_SERVICE_URL
	: 'https://service.klayr.xyz';

export const DEFAULT_CHAIN = process.env.REACT_APP_DEFAULT_CHAIN
	? process.env.REACT_APP_DEFAULT_CHAIN
	: '00';

export const DEFAULT_TOKEN_DECIMAL = process.env.REACT_APP_DEFAULT_TOKEN_DECIMAL
	? process.env.REACT_APP_DEFAULT_TOKEN_DECIMAL
	: '8';

export const DEFAULT_REQUEST_LIMIT = process.env.REACT_APP_DEFAULT_REQUEST_LIMIT
	? process.env.REACT_APP_DEFAULT_REQUEST_LIMIT
	: '10';

export const LAST_BALANCE_UPDATE_INTERVAL = process.env.REACT_APP_LAST_BALANCE_UPDATE_INTERVAL
	? process.env.REACT_APP_LAST_BALANCE_UPDATE_INTERVAL
	: '1d';

export const TOKENS_LIST_DEFAULT_WINDOW = process.env.REACT_APP_TOKENS_LIST_DEFAULT_WINDOW
	? process.env.REACT_APP_TOKENS_LIST_DEFAULT_WINDOW
	: '24h';

export const DEFAULT_FEE_TIER = process.env.REACT_APP_DEFAULT_FEE_TIER
	? process.env.REACT_APP_DEFAULT_FEE_TIER
	: '3000';

export const DEFAULT_DEADLINE_MINUTE = process.env.REACT_APP_DEFAULT_DEADLINE_MINUTE
	? process.env.REACT_APP_DEFAULT_DEADLINE_MINUTE
	: '10';

export const DEFAULT_SLIPPAGE = process.env.REACT_APP_DEFAULT_SLIPPAGE
	? process.env.REACT_APP_DEFAULT_SLIPPAGE
	: '0.5';

export const EFFECT_DEBOUNCE_WAIT = process.env.REACT_APP_EFFECT_DEBOUNCE_WAIT
	? process.env.REACT_APP_EFFECT_DEBOUNCE_WAIT
	: '500';

export const KLYUSD_UPDATE_INTERVAL_MS = process.env.REACT_APP_KLYUSD_UPDATE_INTERVAL_MS
	? process.env.REACT_APP_KLYUSD_UPDATE_INTERVAL_MS
	: '60000';

export const NODE_ENV = process.env.NODE_ENV;
