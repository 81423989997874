export const intervalToLimit = {
	'1h': 12,
	'24h': 288,
	'1d': 288,
	'7d': 2016,
	'1w': 2016,
	'1M': 8640,
	'30d': 8640,
	'1y': 103680,
};
