export const intervalToTimeframe = {
	'1h': '1h',
	'24h': '1h',
	'1d': '1h',
	'7d': '4h',
	'1w': '4h',
	'1M': '1d',
	'30d': '1d',
	'1y': '1w',
};
